import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import { FieldProps } from "../renderFieldInput";

interface TextAreaInputFieldProps extends FieldProps {
  value: string;
}

export const TextAreaInputField = ({ field, value, entityForm, options, extraOptions }: TextAreaInputFieldProps) => {

  return (
    <Controller
      name={extraOptions?.field ? extraOptions?.field : field.field}
      control={entityForm.control}
      defaultValue={value || ""}
      render={({ field: fieldInput }) => (
        <TextField {...fieldInput} disabled={options?.disabled} fullWidth label={field.headerName} multiline variant="outlined" {...options} />
      )}
    />
  );
};
