import { Grid, Box, TextField, IconButton } from "@mui/material";
import { Edit, Save } from "@mui/icons-material";
import { useContext, useState } from "react";
import { StaffWorkLogsContext } from "../StaffWorkLogs";
import { Payment } from "../workLogsUtils";


type NoteFieldProps = {
    adminValidated: boolean;
    handlePaymentUpdate: (data: Partial<Payment>) => void;
};
export const NoteField = ({ adminValidated, handlePaymentUpdate }: NoteFieldProps) => {
    const { payment } = useContext(StaffWorkLogsContext);

    const [isEditingNote, setIsEditingNote] = useState(false);
    const [note, setNote] = useState(payment?.note || "");

    return (
        <Grid item>
            <Box display="flex" alignItems="center">
                <TextField
                    label="Notas"
                    multiline
                    rows={4}
                    fullWidth
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    disabled={adminValidated || isEditingNote === false}
                />
                {isEditingNote ? (
                    <IconButton
                        color="primary"
                        onClick={() => {
                            setIsEditingNote(false);
                            handlePaymentUpdate({ note });
                        }}
                    >
                        <Save fontSize="small" />
                    </IconButton>
                ) : (
                    <IconButton disabled={adminValidated} color="primary" onClick={() => setIsEditingNote(true)}>
                        <Edit fontSize="small" />
                    </IconButton>
                )}
            </Box>
        </Grid>
    );
};
