import { UseRecordsOptions } from "../../api/useRecords";

export const candidatesOptions = {
    fieldsToRemove: {
        fieldTypesToRemove: ["alias"],
        fieldNamesToRemove: ["id"],
    },
    groupsOptions: {
        fieldsWithExtraOptions: [
            {
                field: "files",
                fileFolder: "0dcbd88d-8175-42d6-8ee9-38c86a96f7be",
            },
        ],
    },
    dataGridOptions: {
        quickSearchToolBar: {
            active: true,
            inputLabel: "Pesquise por nome, email ou número de telefone...",
            fieldsToSearch: ["name", "email", "phone"],
          },
    }
} as UseRecordsOptions;
