import axios, { AxiosError } from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import store2 from "store2";
import { api, baseUrl } from "./config";
import { Credentials } from "../types/auth.types";
import { useNavigate } from "react-router-dom";
import { getRecords } from "./useRecords";
import { getCollections } from "./useCollections";

type AuthTokens = {
    access_token: string;
    refresh_token: string;
};

function useLogin() {
    const navigate = useNavigate();

    const { mutate, error, isError } = useMutation<AuthTokens, AxiosError, Credentials>(login, {
        onSuccess: async (authTokens) => {
            const tokens = authTokens;
            store2.set("auth", tokens);
            const user = await getUser();

            switch (user?.role?.id) {
                case "be3622e2-3796-4458-9c8f-e4738c5b4524": // virtual assistant role
                    navigate("/bank_transactions");
                    break;
                case "83e16f3b-d544-433d-8f55-466c51b1246a": // virtual assistant role
                    navigate("/bank_transactions");
                    break;
                default:
                    navigate("/sessions");
                    break;
            }
        },
    });
    return { mutate, error, isError };
}

function useUser() {
    return useQuery({
        queryKey: ["user"],
        queryFn: async () => {
            return getUser();
        },
        staleTime: Infinity,
    });
}

export const useUserNotificationPreferences = () => {
    return useQuery({
        queryKey: ["notifications_user_config"],
        queryFn: async () => {
            const collections = await getCollections();
            const collection = collections.find((collection: any) => collection.collection === "notifications_user_config");
            if (!collection) return null;
            const preferences = await getRecords("notifications_user_config", "*.*", {
                staleTime: Infinity,
            });

            return preferences;
        },
    });
};

export { useLogin, useUser };

const login = async (credentials: Credentials): Promise<AuthTokens> => {
    const response = await axios({
        method: "POST",
        url: `${baseUrl}/auth/login`,
        data: {
            email: credentials.email,
            password: credentials.password,
        },
    });

    return {
        access_token: response.data.data.access_token,
        refresh_token: response.data.data.refresh_token,
    };
};

export async function refreshToken(refresh_token: AuthTokens["refresh_token"]) {
    const response = await axios({
        method: "POST",
        url: `${baseUrl}/auth/refresh`,
        data: {
            refresh_token: refresh_token,
        },
    });

    return response.data.data;
}

export async function getUser() {
    const response = await api({
        method: "GET",
        url: `/users/me?fields=*.*`,
    });

    return response.data.data;
}

export function decodeJWT(token: string) {
    // Split the token into three parts
    const payload = token.split(".")[1];

    // Base64 decode the payload
    const decodedPayload = JSON.parse(atob(payload));

    // Return the decoded payload
    return decodedPayload;
}
