import { DetailHeader } from "../../components/detailHeader/DetailHeader";
import { CircularProgress, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useContext } from "react";
import { RecordContext } from "../../components/record/Record";
import { ClientSessionsSummary } from "../clients/ClientSessionsSummary";
import { useClientSessionsSummary } from "../../api/useClientSessionsSummary";

export const SessionHeader = () => {
	const recordContext = useContext(RecordContext);
	const { data: sessions, status } = useClientSessionsSummary(recordContext?.item?.client?.id);

	if (!recordContext) return null;

	const { client, service } = recordContext.item;

	return (
		<DetailHeader collection={recordContext.collection}>
			<Grid container alignItems="flex-start" wrap="nowrap" display="flex">
				<Grid minWidth={300}>
					<Typography variant="h5">{service?.name}</Typography>
					<Typography variant="body1">{client?.client_id} </Typography>
				</Grid>
				<Grid>
					{status === "loading" ? <CircularProgress /> : null}
					{sessions && (
						<ClientSessionsSummary sessions={sessions} options={{ flexDirection: "row" }} />
					)}
				</Grid>
			</Grid>
		</DetailHeader>
	);
};
