import { MenuItem } from "../components/menu/MenuItems";
import { Collection } from "../types/collections.types";
import { User } from "../types/auth.types";

export const getMenuItems = (data: Collection[], formatedMenuItems: MenuItem[], user: User) => {
    const exclusionsByRole = [
        {
            collection: "sessions",
            role: ["83e16f3b-d544-433d-8f55-466c51b1246a"],
        },
        {
            collection: "schedules",
            role: ["83e16f3b-d544-433d-8f55-466c51b1246a"],
        }
    ];

    const addEvenIfNoCollection = ["staff-work-logs"];

    return formatedMenuItems.filter(
        (formatedItem) =>
            (data.some((menuItem: Collection) => menuItem.collection === formatedItem.collection) ||
                addEvenIfNoCollection.includes(formatedItem.collection)) &&
            !exclusionsByRole.some(
                (exclusion) => exclusion.collection === formatedItem.collection && exclusion.role.includes(user.role.id)
            )
    );
};  
