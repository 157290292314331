import { useCallback } from "react";
import { FormatedField } from "../../../../types/fields.types";
import { handleLabel } from "../../../../utils/handleLabels";
import { handleTypesOfRelatedFields } from "../../../../utils/handleTypesOfRelatedFields";
import { UseFormReturn } from "react-hook-form";
import { differenceBy } from "lodash";
import { AutocompleteRenderGetTagProps, AutocompleteRenderInputParams, Box, Chip, TextField, Typography } from "@mui/material";
import { Avatar } from "../../../fieldsViews/fields/Avatar";
import { FieldOptions } from "../../renderFieldInput";
import { ValueOfField } from "./RelatedInputField";

type InitialValue = {
    label: string;
    id: number | string;
    avatar?: string;
};

export const useHandleInitialValue = (isMultipleRelated: boolean, extraOptions: any, entityForm: UseFormReturn) => {
    const handleInitialValue = useCallback(
        (value: any, field: FormatedField) => {
            const fieldTemplate = field.settings.display_options?.template;

            if (isMultipleRelated) {
                if (!Array.isArray(value)) {
                    value = [value];
                }

                const arrangedValues = handleTypesOfRelatedFields(field, value, "initialValue");

                const initialValue = arrangedValues.map((item: any) => {
                    const manyToAnyFieldTemplate = item.template;

                    const label = handleLabel(item, fieldTemplate || manyToAnyFieldTemplate);
                    const id = field.settings.type === "manytoany" ? item.id : item[`${extraOptions.relatedTable}_id`]?.id;

                    if (item.directus_users_id || item[`${extraOptions.relatedTable}_id`]?.user_id) {
                        return {
                            label: label,
                            id: id,
                            avatar: item.directus_users_id
                                ? item.directus_users_id.avatar
                                : item[`${extraOptions.relatedTable}_id`].user_id.avatar,
                        };
                    } else {
                        return {
                            label: label,
                            id: id,
                        };
                    }
                });

                entityForm.setValue(field.field, initialValue);
                return initialValue;
            } else {
                const label = handleLabel(value, fieldTemplate);
                const id = value.id;

                const initialValue = {
                    label: label ? label : "",
                    id: id ? id : "",
                } as InitialValue;

                const clientRole = "33b88ee8-1af3-4b8f-a2aa-ebe5ba596625";

                if (value.user_id?.role !== clientRole && value.user_id) {
                    initialValue.avatar = value.user_id.avatar;
                }
                entityForm.setValue(field.field, initialValue);
            }
        },
        [entityForm, extraOptions?.relatedTable, isMultipleRelated]
    );

    return handleInitialValue;
};

export type InputValue = {
    label: string;
    id: number | string;
};

export type ComparedValuesFromInput = Array<{
    optionId: number | string;
    id: number | string;
}>;

export type ComparedValuesFromAPI = {
    addedValues: ComparedValuesFromInput;
    removedValues: ComparedValuesFromInput;
};

export const useCompareInputValuesWithValuesFromApi = (field: FormatedField, extraOptions: any) => {
    const compareInputValuesWithValuesFromApi = useCallback(
        (value: any, newValue: Array<InputValue>): ComparedValuesFromAPI => {
            const existingValuesFromApi = value.map((item: any) => {
                return {
                    optionId: field.settings.type === "manytoany" ? item.id : item[`${extraOptions.relatedTable}_id`]?.id,
                    id: item.id,
                };
            });

            const currentValues = newValue.map((item: InputValue) => {
                return { optionId: item.id };
            });

            const addedValues = differenceBy(
                currentValues,
                existingValuesFromApi,
                "optionId"
            ) as unknown as ComparedValuesFromInput;

            const removedValues = differenceBy(
                existingValuesFromApi,
                currentValues,
                "optionId"
            ) as unknown as ComparedValuesFromInput;

            return { addedValues, removedValues };
        },
        [field, extraOptions]
    );

    return compareInputValuesWithValuesFromApi;
};

export const useArrangeValuesForApi = (field: FormatedField, extraOptions: any, recordContext: any) => {
    const arrangeValuesForApi = useCallback(
        (entityForm: UseFormReturn, addedValues: ComparedValuesFromInput, removedValues?: ComparedValuesFromInput) => {
            entityForm.setValue(`multiple_${field.field}`, {
                create: addedValues.map((value: any) => {
                    if (field.settings.interface === "list-m2m") {
                        return {
                            [`${recordContext?.entity}_id`]: recordContext?.id || "+",
                            [`${extraOptions?.relatedTable}_id`]: {
                                id: value.optionId,
                            },
                        };
                    }
                    if (field.settings.type === "manytoany") {
                        return {
                            [`${recordContext?.entity}_id`]: recordContext?.id || "+",
                            item: {
                                id: value.optionId,
                            },
                            collection: extraOptions?.relatedTable,
                        };
                    }
                    return null;
                }),
                update: [],
                delete: removedValues && removedValues.map((value: any) => value.id),
            });
        },
        [
            extraOptions?.relatedTable,
            field.field,
            field.settings.interface,
            field.settings.type,
            recordContext?.entity,
            recordContext?.id,
        ]
    );

    return arrangeValuesForApi;
};

type CompareFunction = (value: InputValue[], newValue: Array<InputValue>) => ComparedValuesFromAPI;

type ArrangeValuesFunction = (
    entityForm: UseFormReturn,
    addedValues: ComparedValuesFromInput,
    removedValues?: ComparedValuesFromInput
) => void;

export const handleMultipleRelated = (
    isMultipleRelated: boolean,
    value: ValueOfField,
    newValue: Array<InputValue> | InputValue | null,
    compareInputValuesWithValuesFromApi: CompareFunction,
    arrangeValuesForApi: ArrangeValuesFunction,
    entityForm: UseFormReturn,
    field: FormatedField
) => {
    if (isMultipleRelated && value && newValue && Array.isArray(newValue)) {
        const { addedValues, removedValues } = compareInputValuesWithValuesFromApi(value, newValue);
        arrangeValuesForApi(entityForm, addedValues, removedValues);
    }

    if (isMultipleRelated && !value && newValue) {
        const currentValues = entityForm.getValues(field.field);

        const addedValues =
            currentValues?.length > 0
                ? entityForm.getValues(field.field)?.map((item: InputValue) => {
                      return { optionId: item.id };
                  })
                : Array.isArray(newValue) &&
                  newValue.map((item: InputValue) => {
                      return { optionId: item.id };
                  });

        if (addedValues) {
            arrangeValuesForApi(entityForm, addedValues);
        }
    }
};

export const renderCustomOption = (props: React.HTMLAttributes<HTMLLIElement>, option: any) => {
    let avatar = null;
    if (option.item.user_id) {
        avatar = option.item.user_id.avatar;
    }
    if (option.item.role) {
        avatar = option.item?.avatar?.id;
    }

    if (option.item.role || option.item.user_id) {
        return (
            <Box component="li" key={option.id} {...props}>
                <Avatar value={avatar} />
                <Typography variant="body1" ml={2}>
                    {option.label}
                </Typography>
            </Box>
        );
    } else {
        return (
            <li {...props} key={option.id}>
                {option.label}
            </li>
        );
    }
};

export const renderCustomTags = (value: any[], getTagProps: AutocompleteRenderGetTagProps) => {
    return value.map((option, index) => {
        const avatar = option.avatar || option.item?.user_id?.avatar || option.item?.avatar?.id;

        if (avatar) {
            return (
                <Chip
                    avatar={<Avatar value={avatar} styles={{ width: 25, height: 25 }} />}
                    label={option.label}
                    {...getTagProps({ index })}
                />
            );
        } else {
            return <Chip label={option.label} {...getTagProps({ index })} />;
        }
    });
};

export const renderCustomInput = (
    params: AutocompleteRenderInputParams,
    field: FormatedField,
    entityForm: UseFormReturn,

    options?: FieldOptions
) => {
    const avatar = entityForm.getValues(field.field)?.avatar || entityForm.getValues(field.field)?.item?.user_id?.avatar;

    if (avatar) {
        return (
            <TextField
                {...params}
                {...options}
                label={field.headerName}
                fullWidth
                InputProps={{ ...params.InputProps, startAdornment: <Avatar value={avatar} /> }}
            />
        );
    }
    return <TextField {...params} {...options} label={field.headerName} fullWidth />;
};
