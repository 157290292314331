import { useState } from "react";
import { Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from "@mui/material";
import { FormatedField } from "../../types/fields.types";
import { useUpdateRecord } from "../../api/useUpdateRecord";

type SessionStatusFieldProps = {
	field: FormatedField;
	value: string;
	eventId: string | number;
	setPreviewIsOpen: (value: boolean) => void;
	refetchSessions: () => void;
};

export const SessionStatusField = ({
	field,
	value,
	eventId,
	setPreviewIsOpen,
	refetchSessions
}: SessionStatusFieldProps) => {
	const [inputValue, setInputValue] = useState<string | null>(value);
	const updateStatus = useUpdateRecord();

	const handleChange = async (event: SelectChangeEvent) => {
		const response = await updateStatus.mutateAsync({
			entity: "sessions",
			id: eventId,
			data: {
				status: event.target.value,
			},
		});

		if (response.id) {
			setPreviewIsOpen(false);
			refetchSessions();
		}

		setInputValue(event.target.value as string);
	};

  
	return (
		<FormControl fullWidth>
			<InputLabel>Estado da sessão</InputLabel>
			<Select value={inputValue || ""} onChange={handleChange} label="Estado da sessão">
				{field.settings.options.choices.map((choice) => {
					return (
						<MenuItem key={choice.value} value={choice.value}>
							{choice.text}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};
