import { Dialog, DialogTitle, Button, DialogContent, DialogActions, CircularProgress, Backdrop } from "@mui/material";
import { useStore } from "../../store/store";
import { useEffect, useState } from "react";
import { convertBmpToJpeg } from "../../utils/imageUtils";
import { EegDocument, ReportData } from "./EegDocument";
import { pdf, PDFViewer } from "@react-pdf/renderer";
import { useFileUploadToField } from "../../api/useFileUploadToField";
import { useDeleteFile } from "../../api/useDeleteFile";
import { differenceInYears, format } from "date-fns";
import { useUser } from "../../api/useAuth";
import { useField } from "../../api/useField";
import { useUpdateRecord } from "../../api/useUpdateRecord";

export const EvaluationReportDialog = () => {
    const { evaluation_report_dialog, notifications, refetchFiles } = useStore();
    const [isLoading, setIsLoading] = useState(false);
    const { data: user_technician } = useUser();
    const uploadFile = useFileUploadToField({
        field: "eeg_report",
        // @ts-ignore
        settings: {
            entity: "evaluations",
            interface: "file",
        },
    });
    const updateRecord = useUpdateRecord();
    const deleteFile = useDeleteFile();
    const main_professional_title_field = useField("technicians", "main_professional_title");
    const data = evaluation_report_dialog.context;
    const report = data?.item;
    const client = report?.treatment?.client;
    const user = client?.user_id;
    const evaluationDate = report?.eeg_session?.start_date
        ? format(new Date(report?.eeg_session?.start_date), "yyyy-MM-dd HH:mm")
        : "";
    const age = client?.birthday ? differenceInYears(new Date(), new Date(client.birthday)).toString() : "";

    const [convertedImagesEo, setConvertedImagesEo] = useState<string[]>([]);
    const [convertedImagesEc, setConvertedImagesEc] = useState<string[]>([]);
    const [convertedImagesOther, setConvertedImagesOther] = useState<string[]>([]);

    const getTechnicianTitle = (value: string) => {
        const choices = main_professional_title_field?.formatedField?.settings?.options?.choices;
        const matchedChoice = choices?.find((choice: any) => choice.value === value);
        return matchedChoice?.text || "";
    };

    const handleSaveReport = async () => {
        notifications.setNotification({
            isOpen: false,
        });

        setIsLoading(true);

        try {
            const blob = await pdf(<EegDocument reportData={reportData} />).toBlob();
            const eegDate = report?.eeg_session?.start_date ? format(new Date(report?.eeg_session?.start_date), "yyyyMMdd") : "";
            const file = new File([blob], `EEG Quantitativo ${user?.first_name} ${user?.last_name} ${eegDate}.pdf`, {
                type: "application/pdf",
            });

            // Delete previous file
            if (report?.eeg_report) {
                await deleteFile.mutateAsync({
                    id: report?.eeg_report?.id || report?.eeg_report,
                    entity: "evaluations",
                });
            }

            // Upload new file
            await uploadFile.mutateAsync({
                file: file,
                entity: "evaluations",
                folder: "a09a0214-e0ee-46f2-a0b4-0c863836ba9e",
                itemId: report?.id,
            });

            // Update user as the one who did the evaluation
            await updateRecord.mutateAsync({
                id: report?.id,
                entity: "evaluations",
                data: {
                    evaluation_done_by: user_technician?.technician_id[0]?.id,
                },
            });

            notifications.setNotification({
                isOpen: true,
                message: `Relatório de avaliação guardado com sucesso`,
                severity: "success",
            });

            refetchFiles.setTimestamp(Date.now());
            evaluation_report_dialog.setIsOpen(false);
        } catch (error: any) {
            const errorMessage = error?.response?.data?.errorMessage || error.message || "Erro ao guardar relatório de avaliação";
            notifications.setNotification({
                isOpen: true,
                message: errorMessage,
                severity: "error",
            });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const convertImages = async () => {
            if (data?.item) {
                const convertImageSet = async (images: any[]) => {
                    return await Promise.all(images.map(async (img: any) => convertBmpToJpeg(img.directus_files_id)));
                };

           
                if (data.item.eo_images.length > 0) {
                    setConvertedImagesEo(await convertImageSet(data.item.eo_images));
                }
                if (data.item.ec_images.length > 0) {
                    setConvertedImagesEc(await convertImageSet(data.item.ec_images));
                }
                if (data.item.other_images.length > 0) {
                    setConvertedImagesOther(await convertImageSet(data.item.other_images));
                }
            }
        };
        convertImages();
    }, [data?.item]);
    

    const readyToOpen = report && user_technician ;


    if (evaluation_report_dialog.isOpen && !readyToOpen)
        return (
            <Backdrop open={true}>
                <CircularProgress color="primary" size={50} />
            </Backdrop>
        );

    const reportData: ReportData = {
        patientName: `${user?.first_name} ${user?.last_name}`,
        birthDate: client?.birthday || "",
        age: age || "",
        evaluationDate: evaluationDate,
        medication: report?.medication || "",
        eyesOpen: {
            incidenciaAbsoluta: report?.eo_absolute_incidence || "",
            conectividade: report?.eo_conectivity || "",
            images: convertedImagesEo,
        },
        eyesClosed: {
            incidenciaAbsoluta: report?.ec_absolute_incidence || "",
            conectividade: report?.ec_conectivity || "",
            images: convertedImagesEc,
        },
        observations: report?.observations || "",
        technicianName: `${user_technician?.first_name} ${user_technician?.last_name}`,
        technicianTitle: getTechnicianTitle(user_technician?.technician_id?.[0]?.main_professional_title) || "",
        technicianLicense: user_technician?.technician_id?.[0]?.license_number || "",
        otherImages: convertedImagesOther,
        signature: user_technician?.technician_id?.[0]?.signature || "",
    };

    return (
        <Dialog
            open={evaluation_report_dialog.isOpen}
            onClose={() => evaluation_report_dialog.setIsOpen(false)}
            maxWidth="lg"
            fullWidth
        >
            <DialogTitle>Relatório de Avaliação</DialogTitle>

            <DialogContent style={{ display: "flex", justifyContent: "center" }}>
                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <PDFViewer width="1000" height="1200">
                        <EegDocument reportData={reportData} />
                    </PDFViewer>
                )}
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={() => evaluation_report_dialog.setIsOpen(false)}>
                    Cancelar
                </Button>
                <Button onClick={handleSaveReport}>
                    {isLoading ? <CircularProgress color="inherit" size={24} /> : "Guardar"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
