export const appUsersRolesFilter = {
    _and: [
        {
            role: {
                id: {
                    _neq: "33b88ee8-1af3-4b8f-a2aa-ebe5ba596625",
                },
            },
        },
    ],
} as any;
