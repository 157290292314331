import { useSearchParams } from "react-router-dom";
import { useState, useEffect, useMemo } from "react";
import { getRecord } from "../api/useRecord";
import { FormatedFields } from "../types/fields.types";
import { Collection } from "../types/collections.types";

type FieldFromParam = {
  entity: string;
  field: string;
  fieldsQuery: string;
  value: string;
};

type RecordFromParam =
  | {
      item?: any;
      fields: FormatedFields;
      collection: Collection;
      id: number | string | undefined | null;
      entity: string;
      queryKey: string;
    }
  | undefined;

export const useItemsFromSearchParams = (entity: string) => {
  const [params] = useSearchParams();
  const [paramFields, setParamFields] = useState<RecordFromParam[] | null>(null);

  const allFields = useMemo(() => getFieldsFromParams(params), [params]);

  useEffect(() => {
    if (paramFields) return;
    getItemFromFields(allFields).then((res) => {
      //@ts-ignore
      setParamFields(res);
    });
  }, [allFields, paramFields]);

  if (!paramFields) return;

  return paramFields.find((field) => field?.entity === entity)?.item;
};

const getItemFromFields = async (allFields: FieldFromParam[]) => {
  const itemsFromFields = await Promise.all(
    allFields.map(async (field) => {
      if (!field.entity) return;
      const fieldsQuery = field.fieldsQuery;
      const response = await getRecord(
        field.entity,
        field.value,
        fieldsQuery ? fieldsQuery : "*.*",
        `searchParams-${field.entity}--${field.value}`
      );

      return response;
    })
  );

  return itemsFromFields.filter((item) => item !== undefined);
};


function getValuesBetweenBrackets(str: string) {
  const regex = /\[([^\]]+)\]/g;
  let match;
  const values = [];

  while ((match = regex.exec(str)) !== null) {
    values.push(match[1]);
  }

  return values;
}

export const getFieldsFromParams = (params: URLSearchParams) => {
  // ex of how to construct a full param: &[treatments][id][client.user_id.*]
  const allFields: Array<FieldFromParam> = [];

  params.forEach((value, key) => {
    allFields.push({
      entity: getValuesBetweenBrackets(key)[0],
      field: getValuesBetweenBrackets(key)[1],
      fieldsQuery: getValuesBetweenBrackets(key)[2],
      value: value,
    });
  });

  return allFields;
};
