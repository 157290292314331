import { LinearProgress, Typography } from "@mui/material";
import { StaffSummaryList } from "./StaffSummaryList";
import { adminUsers } from "./workLogsUtils";
import { useUser } from "../../api/useAuth";

export const StaffWorkLogsList = () => {
    const { data: currentUser, status: currentUserStatus } = useUser();
    if (currentUserStatus === "loading") return <LinearProgress />;

    if (
        !adminUsers.includes(currentUser?.id) &&
        currentUser?.role.id !== "0676bc30-2754-439a-bc48-8e75b81cb36c" &&
        currentUser?.role.id !== "83e16f3b-d544-433d-8f55-466c51b1246a"
    ) {
        return <Typography variant="h6">Você não tem permissão para ver esta página</Typography>;
    }

    return <StaffSummaryList />;
};
