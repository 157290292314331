import { useState } from "react";
import { FormControl, Select, MenuItem, InputLabel, Box, Button, Typography } from "@mui/material";
import { Controller, UseFormReturn } from "react-hook-form";

type CalenderFilterSelectFieldProps = {
    entityForm: UseFormReturn;
    fieldName: string;
    label: string;
    defaultValue?: any;
    options: any;
    propertyToMatch: string;
};

export const CalenderFilterSelectField = ({
    entityForm,
    fieldName,
    label,
    defaultValue = [],
    options,
    propertyToMatch,
}: CalenderFilterSelectFieldProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const styles = {
        "&.Mui-selected": {
            backgroundColor: "#df644952",
        },
        "&.Mui-selected: hover": {
            backgroundColor: "#df644952",
        },
    };

    return (
        <Controller
            name={fieldName}
            control={entityForm.control}
            defaultValue={defaultValue}
            render={({ field: inputField }) => (
                <FormControl fullWidth>
                    <InputLabel>{label}</InputLabel>
                    <Select
                        {...inputField}
                        renderValue={(value) => {
                            return renderMultipleValues(value, options, propertyToMatch, fieldName);
                        }}
                        onOpen={() => setIsOpen(true)}
                        multiple
                        label={label}
                    >
                        {options.map((item: any) => {
                            return (
                                <MenuItem key={item[propertyToMatch]} value={item[propertyToMatch]} sx={styles}>
                                    <SelectOption entityForm={entityForm} choice={item} isOpen={isOpen} fieldName={fieldName} />
                                </MenuItem>
                            );
                        })}
                        <RemoveAllFilters entityForm={entityForm} fieldName={fieldName} />
                    </Select>
                </FormControl>
            )}
        />
    );
};

const renderMultipleValues = (value: Array<string>, options: Array<any>, propertyToMatch: string, fieldName: string) => {
    let result = value
        .map((option: string) => {
            const matchingObj = options.filter((item: any) => item[propertyToMatch] === option);

            return getValue(matchingObj, fieldName);
        })
        .filter((text) => text !== null)
        .join(",") as string;

    if (result.length > 30) {
        result = result.slice(0, 30) + "...";
    }

    return result;
};

const getValue = (matchingObj: Array<any>, fieldName: string) => {
    if (matchingObj) {
        switch (fieldName) {
            case "technician":
                return matchingObj[0]?.user_first_name;
            case "status":
            case "service_category":
                return matchingObj[0]?.text;
            case "clinic_id":
                return matchingObj[0]?.name;
        }
    }
};

type SelectOptionProps = {
    choice: any;
    isOpen: boolean;
    entityForm: UseFormReturn;
    fieldName: string;
};

const SelectOption = ({ choice, isOpen, entityForm, fieldName }: SelectOptionProps) => {
    const getChoiceText = (choice: any, fieldName: string) => {
        switch (fieldName) {
            case "technician":
                return `${choice.user_id?.first_name} ${choice.user_id?.last_name}`;
            case "status":
            case "service_category":
                return choice.text;
            case "clinic_id":
                return choice.name;
        }
    };

    const getValue = (choice: any, fieldName: string) => {
        switch (fieldName) {
            case "technician":
                return choice.id;
            case "status":
            case "service_category":
                return choice.value;
        }
    };

    return (
        <Box display="flex" justifyContent="space-between" flex={1}>
            <Typography variant="body1">{getChoiceText(choice, fieldName)}</Typography>
            {isOpen && (
                <Button
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        entityForm.setValue(fieldName, [getValue(choice, fieldName)]);
                    }}
                >
                    Só
                </Button>
            )}
        </Box>
    );
};

type RemoveAllFiltersProps = {
    entityForm: UseFormReturn;
    fieldName: string;
};

const RemoveAllFilters = ({ entityForm, fieldName }: RemoveAllFiltersProps) => {
    return (
        <MenuItem key="all">
            <Button
                size="small"
                fullWidth
                color="secondary"
                onClick={(e) => {
                    e.stopPropagation();
                    entityForm.setValue(fieldName, []);
                }}
            >
                Remover Filtro
            </Button>
        </MenuItem>
    );
};
