import { Stack, Snackbar as MuiSnackBar } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import React from "react";
import { useStore } from "../../store/store";

export type Notification = {
    isOpen: boolean;
    message?: string;
    severity?: "success" | "info" | "warning" | "error";
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const SnackBar = () => {
    const {
        notifications: { notification, setNotification },
    } = useStore();

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }

        setNotification({ isOpen: false });
    };

    if (!notification) return null;

    return (
        <Stack spacing={2} sx={{ width: "100%" }}>
            <MuiSnackBar
                open={notification.isOpen}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert onClose={handleClose} severity={notification.severity} sx={{ width: "100%" }}>
                    {notification.message}
                </Alert>
            </MuiSnackBar>
        </Stack>
    );
};
