import { Dialog, DialogTitle, Button, DialogContent, DialogActions, TextField, CircularProgress } from "@mui/material";
import { useStore } from "../../store/store";
import axios from "axios";
import { useUser } from "../../api/useAuth";
import { SelectTemplate } from "../selectTemplate/SelectTemplate";
import { useForm, Controller } from "react-hook-form";
import { useState } from "react";

export const SMSDialog = () => {
    const { sms, notifications } = useStore();
    const { data } = useUser();
    const [isLoading, setIsLoading] = useState(false);

    const entityForm = useForm({
        defaultValues: {
            sms: "",
        },
    });

    const handleSend = async (formData: { sms: string }) => {
        notifications.setNotification({
            isOpen: false,
        });
        setIsLoading(true);

        try {
            await axios({
                method: "POST",
                url: "https://hook.eu1.make.com/6yi4p9jfxo7tveao1t650gtm3ggsqpsx",
                data: {
                    type: "sms",
                    content: formData.sms,
                    id: sms.context.item.id,
                    entity: sms.context.entity,
                    phoneNumber: sms.context.item.phone,
                    user_id: data?.id,
                },
            });

            notifications.setNotification({
                isOpen: true,
                message: `SMS enviado com sucesso`,
                severity: "success",
            });

            entityForm.reset();
            sms.setIsOpen(false);
        } catch (error: any) {

            const errorMessage = error?.response?.data?.errorMessage || "Erro ao enviar SMS";
            notifications.setNotification({
                isOpen: true,
                message: errorMessage,
                severity: "error",
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={sms.isOpen} onClose={() => sms.setIsOpen(false)} maxWidth="sm" fullWidth>
            <DialogTitle>Enviar SMS</DialogTitle>
            <form onSubmit={entityForm.handleSubmit(handleSend)}>
                <DialogContent>
                    <SelectTemplate field="sms" entity="leads" entityForm={entityForm as any} />
                    <Controller
                        name="sms"
                        control={entityForm.control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                autoFocus
                                margin="dense"
                                label="Message"
                                type="text"
                                fullWidth
                                multiline
                                rows={4}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={() => sms.setIsOpen(false)}>
                        Cancelar
                    </Button>
                    <Button type="submit">{isLoading ? <CircularProgress color="inherit" size={24} /> : "Enviar"}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};
