import { Box, Button, CircularProgress, Grid, MenuItem, Paper, Select, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Avatar } from "../../components/fieldsViews/fields/Avatar";
import { Controller, UseFormReturn } from "react-hook-form";
import { Link } from "react-router-dom";
import { useUsers } from "../../api/useUsers";
import { filterUsersRoles } from "../../utils/filterUsersRoles";
import { useRecords } from "../../api/useRecords";

type SchedulesHeaderProps = {
    entityForm: UseFormReturn<any>;
};

export const SchedulesHeader = ({ entityForm }: SchedulesHeaderProps) => {
    const { data: users, status: usersStatus } = useUsers("*.*", filterUsersRoles);
    const { data: clinics, status: clinicsStatus } = useRecords("clinics", "*.*");

    if (usersStatus === "loading" || clinicsStatus === "loading") return <CircularProgress />;
    if (usersStatus === "error" || clinicsStatus === "error") return <div>error...</div>;

    return (
        <Paper
            sx={{
                position: "sticky",
                top: "64px",
                backgroundColor: "background.paper",
                zIndex: 100,
                p: 3,
                mr: 2,
            }}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" color="primary" mb={2}>
                    Criar horários
                </Typography>
                <Button variant="outlined" color="primary" size="small" component={Link} to="/schedules">
                    Ver Horários
                </Button>
            </Box>

            <Grid container spacing={2} mb={2} alignItems="center">
                <Grid item xs={12} md={6} display="flex" gap={2}>
                    <Controller
                        name="clinic"
                        control={entityForm.control}
                        defaultValue="1"
                        render={({ field }) => (
                            <Select
                                {...field}
                                fullWidth
                                displayEmpty
                                onChange={(event) => field.onChange(event.target.value)}
                                sx={{ maxHeight: "56px" }}
                            >
                                <MenuItem value="" disabled>
                                    Selecionar Clínica
                                </MenuItem>
                                {clinics?.items.map((clinic: any) => (
                                    <MenuItem key={clinic.id} value={clinic.id}>
                                        {clinic.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                    <Controller
                        name="user"
                        control={entityForm.control}
                        render={({ field }) => (
                            <Select
                                {...field}
                                fullWidth
                                displayEmpty
                                onChange={(event) => field.onChange(event.target.value)}
                                sx={{ maxHeight: "56px" }}
                            >
                                <MenuItem value="" disabled>
                                    Selecionar Técnico
                                </MenuItem>
                                {users?.items.map((user: any) => (
                                    <MenuItem key={user.id} value={user.id}>
                                        <Box display="flex" alignItems="center" gap={2}>
                                            <Avatar value={user.avatar?.id} />
                                            {user.first_name} {user.last_name}
                                        </Box>
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <Controller
                        name="startDate"
                        control={entityForm.control}
                        render={({ field }) => (
                            <DatePicker label="Start Date" onChange={(date: any) => field.onChange(date)} value={field.value} />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <Controller
                        name="endDate"
                        control={entityForm.control}
                        render={({ field }) => (
                            <DatePicker label="End Date" onChange={(date: any) => field.onChange(date)} value={field.value} />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={2} textAlign="right">
                    <Button type="submit" variant="contained" color="secondary">
                        Create Schedules
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
};
