import {
    Box,
    Unstable_Grid2 as Grid,
    Typography,
    DialogTitle,
    Dialog,
    CircularProgress,
    Divider,
    DialogActions,
    Button,
    DialogContent,
    IconButton,
} from "@mui/material";
import { CalendarMonth, Delete } from "@mui/icons-material";
import { useRecord } from "../../api/useRecord";
import { ClientSessionsSummary } from "../clients/ClientSessionsSummary";
import { useClientSessionsSummary } from "../../api/useClientSessionsSummary";
import { Link } from "react-router-dom";
import { useUpdateRecord } from "../../api/useUpdateRecord";
import { fieldsQuery } from "./sessionRoutes";
import { GroupsOptions } from "../../types/groups.types";
import { findField } from "../../utils/findField";
import { SessionStatusField } from "./SessionStatusField";
import { formatGroups } from "../../utils/formatGroups";
import { SessionDetailGroup } from "./SessionDetailGroup";
import { useFields } from "../../api/useFields";
import { useDeleteRecord } from "../../api/useDeleteRecord";
import { useEffect } from "react";
import {  useStore } from "../../store/store";
import { Alerts } from "../../store/store.types";
import { useIsMobile } from "../../utils/useIsMobile";

type EventPriviewProps = {
    previewIsOpen: boolean;
    eventId: number | undefined;
    setPreviewIsOpen: (value: boolean) => void;
    groupsOptions?: GroupsOptions;
    refetchSessions: () => void;
};

export const SessionPreview = ({
    eventId,
    previewIsOpen,
    setPreviewIsOpen,
    groupsOptions,
    refetchSessions,
}: EventPriviewProps) => {
    const { data, status, fetchStatus, refetch } = useRecord("sessions", fieldsQuery, eventId, { enabled: false });
    const { alerts } = useStore();
    const { formatedFields, status: statusFields } = useFields("sessions");
    const { data: sessions } = useClientSessionsSummary(data?.item?.treatment?.client?.id);
    const updateSession = useUpdateRecord();
    const mutation = useDeleteRecord();
    const isMobile = useIsMobile();

    const doStuffAfterDelete = (
        setPreviewIsOpen: (value: boolean) => void,
        refetchSessions: () => void,
        alerts: Alerts
    ): void => {
        alerts.setIsOpen(false);
        setPreviewIsOpen(false);
        refetchSessions();
        return;
    };

    useEffect(() => {
        if (previewIsOpen) {
            refetch();
        }
    }, [previewIsOpen, refetch]);

    useEffect(() => {
        if (alerts.allowExecution && data) {
            mutation.mutate({
                entity: "sessions",
                id: data.item?.id,
                doStuffAfterDelete: () => doStuffAfterDelete(setPreviewIsOpen, refetchSessions, alerts),
            });
            alerts.setAllowExecution(false);
        }
    }, [alerts, alerts.allowExecution, data, mutation, refetchSessions, setPreviewIsOpen]);

    if (fetchStatus === "fetching")
        return (
            <Box
                sx={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 9999, // ensure it's on top of everything else
                }}
            >
                <CircularProgress size={100} />
            </Box>
        );

    if (status !== "success" || statusFields !== "success") return null;

    const { item } = data;
    const { groups } = formatGroups(formatedFields, item, "detail");

    const statusField = findField(formatedFields, "status");

    return (
        <Dialog
            open={previewIsOpen}
            onClose={() => {
                setPreviewIsOpen(false);
            }}
            maxWidth="lg"
            fullWidth
        >
            <DialogContent sx={{ padding: 5 }}>
                <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
                    <Box alignItems="center" display="flex">
                        <CalendarMonth />
                        <Typography variant="h6" sx={{ marginLeft: 2 }}>
                            Detalhe da Sessão
                        </Typography>
                    </Box>
                </DialogTitle>
                <Divider />
                <Grid
                    container
                    spacing={1}
                    rowSpacing={4}
                    justifyContent="flex-start"
                    alignItems="top"
                    sx={{ padding: 4 }}
                    flexDirection="row"
                    lg={12}
                >
                    <Grid  xs={12} lg={4}>
                        <SessionDetailGroup group={groups[0]} item={item} groupOptions={groupsOptions} />
                    </Grid>
                    <Grid  xs={12}lg={4}>
                        <SessionDetailGroup group={groups[1]} item={item} groupOptions={groupsOptions} />
                    </Grid>
                    <Grid>
                        <Divider orientation="vertical" />
                    </Grid>
                    <Grid padding={1} xs={12} ml={isMobile? 0 : 10} lg={2}>
                        {sessions && <ClientSessionsSummary sessions={sessions} options={{ flexDirection: "column" }} />}
                    </Grid>
                </Grid>

                <DialogActions sx={{ p: 2, mt: 4 }}>
                    <Grid container spacing={1} direction="row" justifyContent="space-between" alignItems="center" flexGrow={1}>
                        <Grid xs={12} lg={6}>
                            {updateSession.isLoading ? (
                                <CircularProgress />
                            ) : (
                                statusField &&
                                eventId && (
                                    <SessionStatusField
                                        field={statusField}
                                        value={item?.status}
                                        eventId={eventId}
                                        setPreviewIsOpen={setPreviewIsOpen}
                                        refetchSessions={refetchSessions}
                                    />
                                )
                            )}
                        </Grid>

                        <Grid>
                            <Button size="large" component={Link} to={`/sessions/${item?.id}/update`} sx={{ ml: 1 }}>
                                Editar
                            </Button>
                            <IconButton
                                onClick={() => {
                                    alerts.setIsOpen(true);
                                    alerts.setMessage(createAlertMessage(item));
                                }}
                                size="large"
                                color="error"
                            >
                                <Delete />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

const createAlertMessage = (item: any) => {
    const client = item?.treatment?.client;
    const lead = item?.lead;
    const title = item?.title;

    if (client) {
        return `Esta ação é irreversivel! irá apagar a sessão associada a ${client?.client_id}`;
    }

    if (lead) {
        return `Esta ação é irreversivel! irá apagar a sessão associada a ${lead?.first_name} ${lead?.last_name}`;
    }

    if (title) {
        return `Esta ação é irreversivel! irá apagar a sessão associada a ${title}`;
    }

    return `Esta ação é irreversivel! irá apagar a sessão`;
};


