import { useState, useEffect } from "react";
import { Calendar as ReactBigCalendar, View } from "react-big-calendar";
import { format, startOfWeek, endOfWeek, startOfDay } from "date-fns";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./overrides.css";
import { parseCalendarEvents } from "../../utils/parseCalendarEvents";
import { useRecords } from "../../api/useRecords";
import { Box } from "@mui/material";
import { SessionPreview } from "../../screens/sessions/SessionPreview";
import { sessionsOptions } from "../../screens/sessions/sessionsOptions";
import { fieldsQuery } from "../../screens/sessions/sessionRoutes";
import { CalendarEvents } from "./calendar.types";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { CustomEventWeek, CustomEventDay, CustomEventAgenda } from "./CustomEvent";
import { isLightColor } from "../../utils/isLightColor";
import { useStore } from "../../store/store";
import { useForm } from "react-hook-form";
import { SessionsCalendarHeader } from "../../screens/sessions/SessionsCalendarHeader";
import {
    localizer,
    createCalendarFilter,
    handleCalendarDate,
    handleRanges,
    maxTime,
    minTime,
    scrollToTime,
    useDefaultFilters,
    useEventDrop,
    useHandleSelectSlot,
    useFormats,
} from "./calendarUtils";
import { CalendarLoader } from "./CalendarLoader";
import { useIsMobile } from "../../utils/useIsMobile";
import { useWindowWidth } from "../../utils/useWindowWith";

import "date-fns-tz";

const DnDCalendar = withDragAndDrop(ReactBigCalendar);

type CalendarProps = {
    setView: React.Dispatch<React.SetStateAction<"calendar" | "list">>;
};

export function Calendar({ setView }: CalendarProps) {
    const [eventId, setEventId] = useState<number>();
    const [previewIsOpen, setPreviewIsOpen] = useState<boolean>(false);
    const {
        calendar: { calendarView, setCalendarView, calendarFilters, setCalendarFilters },
    } = useStore();

    const defaultFilters = useDefaultFilters({
        status: ["booked", "confirmed", "awaiting_in_clinic", "in_session", "done"],
    });

    const [calendarEvents, setCalendarEvents] = useState<CalendarEvents>();

    const entityForm = useForm();
    const watchFields = entityForm.watch;
    const isMobile = useIsMobile();
    const windowWith = useWindowWidth();

    const { data, status, refetch } = useRecords("sessions", fieldsQuery, {
        extraOptions: {
            filter: createCalendarFilter(calendarFilters),
            pageSize: 1000,
        },
        enabled: false,
    });

    const onEventChange = useEventDrop(refetch, "sessions");

    useEffect(() => {
        const events = parseCalendarEvents(data?.items);
        setCalendarEvents(events);
    }, [data]);

    useEffect(() => {
        if (calendarFilters === null) {
            setCalendarFilters(defaultFilters);
        }
    }, [calendarFilters, defaultFilters, setCalendarFilters]);

    useEffect(() => {
        if (!calendarFilters) return;

        refetch();
        const subscription = watchFields((value) => {
            if (calendarFilters !== value) {
                if (value.clinic_id === undefined) value.clinic_id = 1;
                if (!calendarFilters?.dateRange)
                    value.dateRange = {
                        start_date: format(startOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd HH:mm:ss"),
                        end_date: format(endOfWeek(new Date(), { weekStartsOn: 1 }), "yyyy-MM-dd HH:mm:ss"),
                    };

                setCalendarFilters({
                    ...calendarFilters,
                    ...value,
                });
            }
        });
        return () => subscription.unsubscribe();
    }, [watchFields, entityForm, setCalendarFilters, calendarFilters, refetch]);

    const { formats } = useFormats("start_date");
    const handleSelectSlot = useHandleSelectSlot();

    return (
        <Box p={2} style={{ width: isMobile ? windowWith - 16 : "100%", backgroundColor: "#fff" }}>
            <SessionsCalendarHeader entityForm={entityForm} calendarFilters={calendarFilters} setView={setView} />
            {status === "loading" ? <CalendarLoader /> : null}
            <DnDCalendar
                localizer={localizer}
                culture="pt-PT"
                components={{
                    week: {
                        // @ts-ignore
                        event: CustomEventWeek,
                    },
                    day: {
                        // @ts-ignore
                        event: CustomEventDay,
                    },
                    agenda: {
                        // @ts-ignore
                        event: CustomEventAgenda,
                        header: <h1>cenas</h1>,
                    },
                }}
                events={calendarEvents}
                // @ts-ignore
                startAccessor="start"
                // @ts-ignore
                endAccessor="end"
                dayLayoutAlgorithm="no-overlap"
                defaultView={isMobile ? "agenda" : calendarView}
                style={{ height: "calc(100vh - 150px)", position: "relative" }}
                min={minTime}
                max={maxTime}
                timeslots={4}
                step={15}
                scrollToTime={scrollToTime}
                date={calendarFilters?.dateRange && handleCalendarDate(calendarFilters.dateRange)}
                // @ts-ignore
                onSelectEvent={(event: CalendarEvent) => {
                    setEventId(event.id);
                    setPreviewIsOpen(true);
                }}
                selectable={true}
                onSelectSlot={(e) =>
                    handleSelectSlot({
                        start: e.start,
                        entity: "sessions",
                        defaultMinutes: 60,
                    })
                }
                onView={(view: View) => {
                    setCalendarView(view);
                }}
                onNavigate={(date) => {}}
                onRangeChange={(range: any, view: View | undefined) => {
                    const newFilters = {
                        ...calendarFilters,
                        dateRange: handleRanges(view === "day" ? [startOfDay(new Date())] : range),
                    };
                    setCalendarFilters(newFilters);
                }}
                eventPropGetter={(event) => {
                    const defaultColor = "#d1cfcf";
                    // @ts-ignore
                    const eventColor = event?.color ? event?.color : defaultColor;

                    return {
                        style: {
                            backgroundColor: eventColor,
                            borderColor: eventColor,
                            // @ts-ignore
                            color: isLightColor(eventColor) ? "#404040" : "#fff",
                            fontSize: "11px",
                        },
                    };
                }}
                // @ts-ignore
                formats={formats}
                draggableAccessor={(event) => true}
                onEventDrop={onEventChange}
            />
            <SessionPreview
                previewIsOpen={previewIsOpen}
                setPreviewIsOpen={setPreviewIsOpen}
                eventId={eventId}
                groupsOptions={sessionsOptions}
                refetchSessions={refetch}
            />
        </Box>
    );
}
