import { Drawer, Toolbar, Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { MenuItems } from "./MenuItems";
import { useTheme } from "@mui/material/styles";
import { MenuUser } from "./MenuUser";
import Logo from "/assets/images/logo.png";
import { useStore } from "../../store/store";
import { useIsMobile } from "../../utils/useIsMobile";
import { mainMenuItems } from "./MainMenuItems";
import { SettingsMenu } from "./SettingsMenu";
import { useUser } from "../../api/useAuth";

export function Menu() {
    let location = useLocation();
    const theme = useTheme();
    const isMobile = useIsMobile();
    const { menu } = useStore();
    const { data: user, status } = useUser();

    const menuStyle = {
        width: 240,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
            width: 240,
            boxSizing: "border-box",
            borderRight: "none",
            borderRadius: "0px 0 30px 0",
        },
        zIndex: theme.zIndex.drawer + 1,
        overflow: "hidden",
    };

    if (location.pathname === "/login") return null;

    return (
        <Box sx={{ display: "flex", backgroundColor: "#fff" }}>
            <Drawer
                sx={!isMobile ? menuStyle : null}
                variant="persistent"
                anchor="left"
                open={(!isMobile ? true : false) || menu.isOpen}
            >
                <Toolbar>{!isMobile ? <img src={Logo} alt="Logo" style={{ width: "100%", height: "auto" }} /> : null}</Toolbar>
                {status === "success" ? (
                    <>
                        <MenuItems items={mainMenuItems} user={user} />
                        <Box sx={{ flex: "1 1 auto" }} /> {/* Spacer */}
                        <SettingsMenu />
                        <MenuUser user={user} />
                    </>
                ) : null}
            </Drawer>
        </Box>
    );
}
