import { Calendar as ReactBigCalendar, View } from "react-big-calendar";
import { Link } from "react-router-dom";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "../../components/calendar/overrides.css";
import { useRecords } from "../../api/useRecords";
import { ScheduleCustomEvent } from "./ScheduleCustomEvent";
import { Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import {
    handleCalendarDate,
    handleRanges,
    localizer,
    useDefaultFilters,
    useEventDrop,
    useFormats,
    useHandleSelectSlot,
} from "../../components/calendar/calendarUtils";
import { useNavigate } from "react-router-dom";
import { set, startOfDay } from "date-fns";
import { useStaffAvailability } from "./scheduleUtils";
import { useStore } from "../../store/store";
import { filterUsersRoles } from "../../utils/filterUsersRoles";
import { useUsers } from "../../api/useUsers";
import { Avatar } from "../../components/fieldsViews/fields/Avatar";

const minTime = set(new Date(), { hours: 6, minutes: 0, seconds: 0 });
const maxTime = set(new Date(), { hours: 23, minutes: 59, seconds: 59 });

export const SchedulesCalendar = () => {
    const { data: services, status: servicesStatus } = useRecords("services", "*.*", { staleTime: Infinity });
    const { data: users } = useUsers("*.*", filterUsersRoles);

    const {
        schedules: {
            calendarFilters,
            setCalendarFilters,
            servicesSelected,
            setServicesSelected,
            eventTypeFilter,
            setEventTypeFilter,
            userId,
            setUserId,
        },
    } = useStore();

    const handleSelectSlot = useHandleSelectSlot();
    const navigate = useNavigate();
    const { formats } = useFormats("both");
    const defaultFilters = useDefaultFilters();

    const { events, isLoading, isError, refetch } = useStaffAvailability(calendarFilters || defaultFilters);
    const onEventChange = useEventDrop(refetch, "schedules");

    if (isLoading || servicesStatus === "loading") return <CircularProgress />;
    if (isError || servicesStatus === "error") return <div>error...</div>;

    const filteredEvents = events.filter(
        (event) =>
            (eventTypeFilter === "all" || event.type === eventTypeFilter) &&
            (servicesSelected.length === 0 || event.services?.some((service: string) => servicesSelected.includes(service))) &&
            (!userId || event.userId === userId)
    );

    const DnDCalendar = withDragAndDrop(ReactBigCalendar);

    return (
        <Box>
            <Grid container spacing={2} alignItems="center" justifyContent="space-between" style={{ marginBottom: "20px" }}>
                <Grid item xs={3}>
                    <Select
                        fullWidth
                        displayEmpty
                        value={userId}
                        onChange={(e) => setUserId(e.target.value)}
                        sx={{ maxHeight: "56px" }}
                    >
                        <MenuItem value="" disabled>
                            Selecionar Técnico
                        </MenuItem>
                        {users?.items.map((user: any) => (
                            <MenuItem key={user.id} value={user.id}>
                                <Box display="flex" alignItems="center" gap={2}>
                                    <Avatar value={user.avatar?.id} />
                                    {user.first_name} {user.last_name}
                                </Box>
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <InputLabel>Serviços</InputLabel>
                        <Select
                            multiple
                            value={servicesSelected}
                            onChange={(e) => {
                                const value = e.target.value as string[];
                                setServicesSelected(value.includes("") ? [] : value);
                            }}
                            label="Serviços"
                            renderValue={(selected) =>
                                services?.items
                                    .filter((service: any) => selected.includes(service.id))
                                    .map((service: any) => service.name)
                                    .join(", ")
                            }
                        >
                            <MenuItem value={""}>Remover</MenuItem>
                            {services?.items.map((service: any) => (
                                <MenuItem key={service.id} value={service.id}>
                                    {service.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <InputLabel>Event Type</InputLabel>
                        <Select
                            value={eventTypeFilter}
                            onChange={(e) => setEventTypeFilter(e.target.value as string)}
                            label="Event Type"
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="schedule">Schedules</MenuItem>
                            <MenuItem value="time_off">Time Offs</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3} display="flex" justifyContent="center" gap={2}>
                    <Button variant="contained" size="small" color="primary" component={Link} to="/schedules/add_multiple">
                        Adicionar Múltiplos
                    </Button>
                    <Button variant="contained" color="secondary" component={Link} to="/time_off/create">
                        Criar indisponibilidade
                    </Button>
                </Grid>
            </Grid>

            <Box style={{ height: "1000px" }}>
                <DnDCalendar
                    localizer={localizer}
                    culture="pt-PT"
                    events={filteredEvents}
                    date={calendarFilters?.dateRange && handleCalendarDate(calendarFilters.dateRange)}
                    onNavigate={(date) => {}}
                    // @ts-ignore
                    startAccessor="start"
                    // @ts-ignore
                    endAccessor="end"
                    defaultView="week"
                    min={minTime}
                    max={maxTime}
                    timeslots={4}
                    step={15}
                    views={["week"]}
                    selectable={true}
                    onSelectSlot={(e) =>
                        handleSelectSlot({
                            start: e.start,
                            entity: "schedules",
                            defaultMinutes: 240,
                        })
                    }
                    onSelectEvent={(event: any) => {
                        const entity = event.type === "schedule" ? "schedules" : "time_off";
                        navigate(`/${entity}/${event.id}/update`);
                    }}
                    components={{
                        // @ts-ignore
                        event: ScheduleCustomEvent,
                    }}
                    eventPropGetter={(event: any) => {
                        return {
                            style: {
                                backgroundColor: event.userColor,
                            },
                        };
                    }}
                    // @ts-ignore
                    formats={formats}
                    draggableAccessor={(event) => true}
                    onEventDrop={onEventChange}
                    resizable
                    onEventResize={onEventChange}
                    onRangeChange={(range: any, view: View | undefined) => {
                        const newFilters = {
                            ...calendarFilters,
                            dateRange: handleRanges(view === "day" ? [startOfDay(new Date())] : range),
                        };

                        setCalendarFilters(newFilters);
                    }}
                />
            </Box>
        </Box>
    );
};
