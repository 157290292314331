import { format, addDays, isBefore, isAfter, setHours, setMinutes } from "date-fns";
import { useRecords } from "../../api/useRecords";
import { createCalendarFilter } from "../../components/calendar/calendarUtils";
import { CustomEventType } from "./ScheduleCustomEvent";

interface TimeSlot {
    start: string;
    end: string;
    services: Array<{ id: number }>;
    notes: string;
}

interface Schedule {
    [key: string]: TimeSlot[];
}

interface FormattedSchedule {
    start_date: string;
    end_date: string;
    user: string;
    clinic_id: string;
    services: Array<{
        schedules_id: string;
        services_id: { id: number };
    }>;
    notes: string;
}

export const createFormattedSchedules = (startDate: Date, endDate: Date, user: string, clinic: string, schedules: Schedule) => {
    // Set endDate to end of day (23:59:59)
    const adjustedEndDate = new Date(endDate);
    adjustedEndDate.setHours(23, 59, 59, 999);

    const formattedSchedules: Array<FormattedSchedule> = [];

    let currentDate = startDate;

    while (isBefore(currentDate, adjustedEndDate) || currentDate.getTime() === adjustedEndDate.getTime()) {
        const dayOfWeek = format(currentDate, "EEEE");

        const daySchedules = schedules[dayOfWeek];

        daySchedules.forEach((slot) => {
            if (slot.start && slot.end && slot.services) {
                const startDateTime = new Date(slot.start);
                const endDateTime = new Date(slot.end);

                const startHour = startDateTime.getHours();
                const startMinute = startDateTime.getMinutes();
                const endHour = endDateTime.getHours();
                const endMinute = endDateTime.getMinutes();

                const slotStartDateTime = setMinutes(setHours(currentDate, startHour), startMinute);
                const slotEndDateTime = setMinutes(setHours(currentDate, endHour), endMinute);

                const isWithinDateRange = !isBefore(slotStartDateTime, startDate) && !isAfter(slotEndDateTime, adjustedEndDate);

                if (isWithinDateRange) {
                    formattedSchedules.push({
                        start_date: slotStartDateTime.toISOString(),
                        end_date: slotEndDateTime.toISOString(),
                        user: user,
                        clinic_id: clinic,
                        services: slot.services.map((service) => ({
                            schedules_id: "+",
                            services_id: { id: service.id },
                        })),
                        notes: slot.notes || "",
                    });
                }
            }
        });

        currentDate = addDays(currentDate, 1);
    }

    return formattedSchedules;
};

export const useStaffAvailability = (filter: any) => {
    const filters = createCalendarFilter(filter);

    const { data, status, refetch } = useRecords("schedules", "*,services.*.*,user.*.*", {
        extraOptions: { filter: filters },
    });

    const { data: timeOff, status: timeOffStatus } = useRecords("time_off", "*,user.*", {
        extraOptions: { filter: filters },
    });

    const scheduleEvents =
        data?.items.map((schedule: any) => {
            return {
                id: schedule.id,
                title: schedule.services?.map((service: any) => service.services_id?.name).join(", ") || "Unspecified",
                services: schedule.services?.map((service: any) => service.services_id?.id),
                start: new Date(schedule.start_date),
                end: new Date(schedule.end_date),
                notes: schedule.notes,
                userId: schedule.user?.id,
                userColor: schedule.user?.technician_id[0]?.color,
                userName: `${schedule.user.first_name} ${schedule.user.last_name}`,
                userAvatar: schedule?.user?.avatar?.id,
                type: "schedule",
            };
        }) || ([] as CustomEventType[]);

    const timeOffEvents =
        timeOff?.items.map((timeOff: any) => {
            return {
                id: timeOff.id,
                start: new Date(timeOff.start_date),
                end: new Date(timeOff.end_date),
                notes: timeOff.notes,
                userColor: "#e63131",
                userName: `${timeOff.user?.first_name} ${timeOff.user?.last_name}`,
                userAvatar: timeOff?.user?.avatar?.id,
                type: "time_off",
            };
        }) || ([] as CustomEventType[]);

    const isLoading = status === "loading" || timeOffStatus === "loading";
    const isError = status === "error" || timeOffStatus === "error";

    const events = [...scheduleEvents, ...timeOffEvents];

    return { events, isLoading, isError, refetch };
};
