import {
    parseISO,
    format,
    isSameDay,
    differenceInHours,
    differenceInMinutes,
    min,
    max,
    eachDayOfInterval,
    isSunday,
    startOfDay,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useRecords } from "../../api/useRecords";
import { useFieldSelectOptions } from "../../utils/useFieldSelectOptions";
import { useEffect, useMemo } from "react";
import { useUser } from "../../api/useAuth";

import { useSearchParams } from "react-router-dom";
import { useStore } from "../../store/store";
import { orderBy } from "lodash";

const TIMEZONE = "Europe/Lisbon";

const calculateTimeOffTotals = (entries: any[]) => {
    const holidayDays = entries.reduce((sum, entry) => {
        const start = startOfDay(parseISO(entry.start_date));
        const end = startOfDay(parseISO(entry.end_date));
        
        const daysInRange = eachDayOfInterval({ start, end });
        const workDays = daysInRange.filter((day) => !isSunday(day)).length;

        return sum + workDays;
    }, 0);

    return {
        totalHolidays: holidayDays,
    };
};

export const calculateDayStats = (logs: WorkLog[]) => {
    if (logs.length === 0) return { timeStart: "", timeEnd: "", workHours: 0, clientsHours: 0, timeOffHours: 0 };

    const timeStart = min(logs.map((entry) => parseISO(entry.start_date)));
    const timeEnd = max(logs.map((entry) => parseISO(entry.end_date)));

    const workTotals = calculateHoursTotals(logs);

   
    return {
        timeStart: format(timeStart, "HH:mm"),
        timeEnd: format(timeEnd, "HH:mm"),
        workHours: workTotals.schedules,
        clientsHours: workTotals.clients,
    };
};

export const calculateTotals = (workLogs: WorkLog[], timeOff: TimeOff[]): Totals => {
    const workTotals = calculateHoursTotals(workLogs);
    const { totalHolidays } = calculateTimeOffTotals(timeOff);

    return {
        totalWorkHours: workTotals.schedules,
        effectiveWorkHours: workTotals.schedules,
        totalClientHours: workTotals.clients,
        totalHolidays: totalHolidays,
    };
};

export const formatToLisbonTime = (dateString: string) => {
    const date = utcToZonedTime(parseISO(dateString), TIMEZONE);
    return format(date, "yyyy-MM-dd'T'HH:mm");
};

export const calculateHours = (start: string, end: string) => {
    const startDate = utcToZonedTime(parseISO(start), TIMEZONE);
    const endDate = utcToZonedTime(parseISO(end), TIMEZONE);
    const hours = differenceInHours(endDate, startDate);
    const minutes = differenceInMinutes(endDate, startDate) % 60;
    return `${hours}h ${minutes}m`;
};

export const calculateHoursTotals = (entries: any[]) => {
    const types = ["schedules", "clients"];
    return types.reduce((totals: Record<string, number>, type) => {
        totals[type] = entries
            .filter((entry) => entry.type === type)
            .reduce((sum, entry) => sum + convertToHours(calculateHours(entry.start_date, entry.end_date)), 0);
        return totals;
    }, {});
};

function convertToHours(timeString: string): number {
    const [hours, minutes] = timeString.split("h ");
    return parseInt(hours) + parseInt(minutes) / 60;
}

export const getDaysArray = (start: Date, end: Date) => {
    const arr = [];
    for (let dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
        if (dt.getDay() !== 0) {
            arr.push(new Date(dt));
        }
    }
    return arr;
};

const createTimeOffEntries = (timeOffRaw: any[]): TimeOff[] => {
    return timeOffRaw.flatMap((entry) => {
        const start = parseISO(entry.start_date);
        const end = parseISO(entry.end_date);

        if (isSameDay(start, end)) {
            return [entry];
        }

        return getDaysArray(start, end).map((day) => ({
            ...entry,
            start_date: format(day, "yyyy-MM-dd'T'10:00:00"),
            end_date: format(day, "yyyy-MM-dd'T'18:00:00"),
        }));
    });
};

export const useWorkLogs = (startDate: Date, endDate: Date, userId?: string) => {
    const dateFilter = {
        start_date: { _gte: startDate.toISOString(), _lte: endDate.toISOString() },
    };

    const technicianUser = userId ? { technician: { user_id: { id: { _eq: userId } } } } : {};

    const user = userId ? { user: { id: { _eq: userId } } } : {};

    const { data: currentUser, status: currentUserStatus } = useUser();

    const { data: sessions, status: sessionsStatus } = useRecords(
        "sessions",
        "id,start_date,end_date,title,technician.user_id.*",
        {
            extraOptions: {
                filter: {
                    ...dateFilter,
                    ...technicianUser,
                    status: { _eq: "done" },
                    service: { _nnull: true },
                },
                pageSize: -1,
            },
        }
    );

    const { data: schedules, status: schedulesStatus } = useRecords("schedules", "id,start_date,end_date,notes,user.*", {
        extraOptions: {
            filter: {
                ...dateFilter,
                ...user,
            },
            pageSize: -1,
        },
    });

    const { data: timeOffRaw, status: timeOffStatus } = useRecords("time_off", "*.*", {
        extraOptions: {
            filter: {
                ...dateFilter,
                ...user,
            },
            pageSize: -1,
        },
    });
    const year = startDate.getFullYear();

    const { data: fullYearTimeOff, status: fullYearTimeOffStatus } = useRecords("time_off", "*.*", {
        extraOptions: {
            filter: {
                start_date: { _gte: `${year}-01-01` },
                end_date: { _lt: `${year + 1}-01-01` },
                user: { id: { _eq: userId } },
            },
        },
    });

    const timeOffOptions = useFieldSelectOptions("time_off", "type");

    const createWorklogs = (sessions: any[], schedules: any[]): WorkLog[] => {
        const worklogs: WorkLog[] = [];

        sessions.forEach((session) => {
            worklogs.push({
                id: session.id,
                start_date: session.start_date,
                end_date: session.end_date,
                user: session.technician?.user_id,
                type: "clients",
            });
        });

        schedules.forEach((schedule) => {
            worklogs.push({
                id: schedule.id,
                start_date: schedule.start_date,
                end_date: schedule.end_date,
                user: schedule.user,
                type: "schedules",
                notes: schedule.notes,
            });
        });

        return worklogs;
    };

    const workLogs =
        sessionsStatus === "success" && schedulesStatus === "success" ? createWorklogs(sessions.items, schedules.items) : [];

    const timeOff = timeOffStatus === "success" ? createTimeOffEntries(timeOffRaw.items) : [];

    const yearHolidays = fullYearTimeOff ? calculateTimeOffTotals(fullYearTimeOff.items) : { totalHolidays: 0 };
    const thisYearHolidays = yearHolidays.totalHolidays;

    const isLoading =
        sessionsStatus === "loading" ||
        schedulesStatus === "loading" ||
        timeOffStatus === "loading" ||
        currentUserStatus === "loading" ||
        fullYearTimeOffStatus === "loading";
    const isError =
        sessionsStatus === "error" ||
        schedulesStatus === "error" ||
        timeOffStatus === "error" ||
        currentUserStatus === "error" ||
        fullYearTimeOffStatus === "error";

    return {
        workLogs,
        timeOff,
        timeOffTypes: timeOffOptions,
        thisYearHolidays,
        currentUser,
        isLoading,
        isError,
    };
};

const rolesMapping = [
    {
        role: "admin",
        id: "d6b0779e-70fc-4854-9525-91e41e555389",
    },
    {
        role: "directors",
        id: "0676bc30-2754-439a-bc48-8e75b81cb36c",
    },
    {
        role: "nfb_techncians",
        id: "55720c74-c013-44f0-96e5-3737adf14963",
    },
    {
        role: "theurapist",
        id: "0295dd26-17ab-454c-bacf-044dd349f21e",
    },
    {
        role: "recepcionist",
        id: "3b835836-7e73-4f72-af50-597f9a49261f",
    },
    {
        role: "other",
        id: "0675b953-7b2a-4778-b578-2818b22794d8",
    },
];

export const useDataForExport = (employeeSummaries: any[]) => {
    const data = useMemo(() => {
        const roleOrder = new Map(rolesMapping.map((role, index) => [role.id, index]));
        const PUSH_TO_END = 999999;
        const SUBSIDY_AGREEMENT_TYPES = ["term_contract", "full_term"];

        const sortedSummaries = orderBy(employeeSummaries, (summary) => roleOrder.get(summary.user.role) ?? PUSH_TO_END);

        const mapToExportFormat = (summary: any) => ({
            name: `${summary.user.first_name} ${summary.user.last_name}`,
            nif: summary.payment?.staff_agreement?.nif || "",
            amount_to_pay: summary.payment?.amount_receipt_to_transfer || "",
            subsidy: summary.payment?.subsidy || "",
            iban: summary.payment?.staff_agreement?.iban || "",
            description: SUBSIDY_AGREEMENT_TYPES.includes(summary.payment?.staff_agreement?.agreement_type)
                ? `Salario ${summary.user.first_name} ${summary.user.last_name}`
                : `PS ${summary.user.first_name} ${summary.user.last_name}`,
            [""]: "",
        });

        const salariesData = sortedSummaries
            .filter((summary) => summary.payment?.staff_agreement?.agreement_type)
            .map(mapToExportFormat);

        const subsidiesData = sortedSummaries
            .filter((summary) => SUBSIDY_AGREEMENT_TYPES.includes(summary.payment?.staff_agreement?.agreement_type))
            .map(mapToExportFormat);

        return { salariesData, subsidiesData };
    }, [employeeSummaries]);

    const salaryColumns = [
        { key: "iban", width: 30, header: "IBAN" },
        { key: "name", width: 20, header: "Name" },
        { key: "", width: 20, header: "" },
        { key: "amount_to_pay", width: 15, header: "Amount to Pay" },
        { key: "description", width: 30, header: "Description" },
    ];

    const subsidyColumns = [
        { key: "nif", width: 20, header: "NIF Colaborador" },
        { key: "name", width: 20, header: "Nome do Colaborador" },
        { key: "subsidy", width: 15, header: "Valor" },
    ];

    return {
        salariesData: data.salariesData,
        subsidiesData: data.subsidiesData,
        salaryColumns,
        subsidyColumns,
    };
};

export const useStaffWorkLogsFilter = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { selectedYear, setSelectedYear, selectedMonth, setSelectedMonth } = useStore((state) => state.staffWorkLogs);

    useEffect(() => {
        const yearParam = searchParams.get("year");
        const monthParam = searchParams.get("month");

        if (yearParam) {
            setSelectedYear(parseInt(yearParam));
        }
        if (monthParam) {
            setSelectedMonth(parseInt(monthParam) - 1); // Subtract 1 since months are 0-based in JS
        }
    }, [searchParams, setSelectedYear, setSelectedMonth]);

    useEffect(() => {
        setSearchParams({
            year: selectedYear.toString(),
            month: (selectedMonth + 1).toString(), // Add 1 to match human-readable months (1-12)
        });
    }, [selectedYear, selectedMonth, setSearchParams]);

    return { selectedYear, setSelectedYear, selectedMonth, setSelectedMonth };
};

export const adminUsers = [
    "c6ff41ec-ef5d-48e4-b874-94b9d48e69c8",
    "9b0bad79-56a2-43a8-b1c8-bc8ac8e48462",
    "82ad1c65-f7f1-4608-8556-58332cf08114",
]; // Shilan, Jorge, Contabilidade e Impersonate

export type WorkLog = {
    id: string;
    start_date: string;
    end_date: string;
    type: "schedules" | "clients" | "holiday";
    user: string;
    notes?: string;
};

export type TimeOff = {
    id: string;
    start_date: string;
    end_date: string;
    type: "time_off";
    user: string;
};

export type TimeOffType = {
    text: string;
    value: string;
};

export type Totals = {
    totalWorkHours: number;
    effectiveWorkHours: number;
    totalClientHours: number;
    totalHolidays: number;
};

export type Payment = {
    id: string;
    user: {
        id: string;
    };
    month: number;
    year: number;
    value: number;
    admin_validated: boolean;
    staff_validated: boolean;
    documents: any;
    gross: number;
    net: number;
    amount_receipt_to_transfer: number;
    staff_agreement: {
        agreement_type: string;
    };
    subsidy: number;
    note: string;
};

export type StaffAgreement = {
    id: string;
    agreement_type: string;
    iban: string;
    nif: string;
    value_per_hour: number;
    value_per_client: number;
    user: {
        id: string;
        first_name: string;
        last_name: string;
        avatar: string;
    };
};

export const filterEmployeeSummaries = (employeeSummaries: any[], selectedAgreementType: string) => {
    return employeeSummaries.filter((summary) => {
        const agreementType = summary.payment?.staff_agreement?.agreement_type;
        if (selectedAgreementType === "all") return true;
        if (selectedAgreementType === "services_provider" && agreementType === "services_provider") return true;
        if (selectedAgreementType === "contracts" && ["full_term", "term_contract"].includes(agreementType)) return true;
        return false;
    });
};
