import { api } from "../../api/config";
import { RecordContextType } from "../../components/record/Record";
import { getStore } from "../../store/store";
import { User } from "../../types/auth.types";

export const actions = [
    {
        code: "create_pdf_report",
        name: "Criar PDF da avaliação",
        actionFn: (context: any, executeAction: any) => openEvaluationDialog(context),
    },
    {
        code: "send_evaluation_to_client",
        name: "Enviar avaliação / EEG  para cliente",
        actionFn: (context: RecordContextType, code: string, user: User) => sendEvaluationToClient(context, code, user),
    },
];

const sendEvaluationToClient = async (context: RecordContextType, code: string, user: User) => {
    const confirmed = window.confirm("De certeza que deseja enviar esta avaliação para o cliente?");
    if (!confirmed) return;

    const response = await api({
        method: "POST",
        url: "https://hook.eu1.make.com/4xhf89f57d3y2atetehn1t1pccs1dwrz",
        data: {
            item: context.item.id,
            userId: user.id,
            action: code,
        },
    });

    return response.data;
};

const openEvaluationDialog = (context: RecordContextType) => {
    try {
        const { evaluation_report_dialog } = getStore();
        evaluation_report_dialog.setIsOpen(true);
        evaluation_report_dialog.setContext(context);

        return {
            showSuccessMessage: "no",
        };
    } catch (error: any) {
        console.log(error);
        throw new Error("algo correu mal");
    }
};
