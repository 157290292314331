import { Record } from "../../components/record/Record";
import { Records } from "../../components/records/Records";
import { tabs } from "./candidatesTabs";
import { DetailTabs } from "../../components/detailTabs/DetailTabs";
import { EntityForm } from "../../components/entityForm/EntityForm";
import { Groups } from "../../components/groups/Groups";
import { candidatesOptions } from "./candidatesOptions";
import { CandidatesListHeader } from "./CandidatesListHeader";
import { CandidatesDetailHeaderHeader } from "./CandidatesDetailHeader";
import { NotesComponent } from "../../components/notes/NotesComponent";

const fieldsQuery = "*.*";

export const candidatesRoutes = [
    {
        path: "/candidates",
        element: (
            <Records
                entity="candidates"
                fieldsQuery={fieldsQuery}
                options={candidatesOptions}
                header={<CandidatesListHeader />}
                heightOfHeaders={264}
            />
        ),
    },
    {
        path: "/candidates/:id",
        element: (
            <Record
                entity="candidates"
                type="detail"
                fieldsQuery={fieldsQuery}
                body={<DetailTabs tabs={tabs} />}
                header={<CandidatesDetailHeaderHeader />}
            />
        ),
        children: [
            {
                path: "/candidates/:id/detail",
                element: <Groups groupsOptions={candidatesOptions.groupsOptions} />,
            },
            {
                element: <NotesComponent />,
                path: "/candidates/:id/notes",
            },
        ],
    },
    {
        path: "/candidates/:id/update",
        element: (
            <Record
                entity="candidates"
                type="update"
                fieldsQuery={fieldsQuery}
                body={<EntityForm />}
                header={<CandidatesDetailHeaderHeader />}
            />
        ),
    },
    {
        path: "/candidates/create",
        element: <Record entity="candidates" type="create" fieldsQuery={fieldsQuery} body={<EntityForm />} />,
    },
];
