import CssBaseline from "@mui/material/CssBaseline";
import { router } from "./browser-router/router";
import { RouterProvider } from "react-router-dom";


export function App() {

    return (
        <div className="App">
            <CssBaseline />
            <RouterProvider router={router} />
        </div>
    );
}

export default App;
