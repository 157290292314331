import React from "react";
import { Button } from "@mui/material";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";

interface Column {
    key: string;
    width: number;
    header?: string;
}

type ExportExcelProps = {
    data: Record<string, any>[];
    columns: Column[];
    fileName: string;
    buttonText?: string;
    includeHeaders?: boolean;
};

export const ExportExcel = ({
    data,
    columns,
    fileName,
    buttonText = "Export Data",
    includeHeaders = false,
}: ExportExcelProps) => {
    const exportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Sheet1");

        // Set column widths and headers if included
        worksheet.columns = columns.map((col) => ({
            header: includeHeaders ? col.header || col.key : undefined,
            key: col.key,
            width: col.width,
        }));

        // Add data rows
        data.forEach((row) => {
            worksheet.addRow(columns.map((col) => row[col.key]));
        });

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(blob, `${fileName}.xlsx`);
    };

    return (
        <Button variant="contained" onClick={exportToExcel} sx={{ minWidth: 200 }} color="secondary">
            {buttonText}
        </Button>
    );
};
