import { CalendarMonth, FormatListBulleted } from "@mui/icons-material";
import { Unstable_Grid2 as Grid, IconButton } from "@mui/material";

type ToggleBetweenCalendarAndListProps = {
    setView: React.Dispatch<React.SetStateAction<"calendar" | "list">>;
};

export const ToggleBetweenCalendarAndList = ({ setView }: ToggleBetweenCalendarAndListProps) => {
    return (
        <Grid container spacing={2} alignItems="center" >
            <Grid>
                <IconButton onClick={() => setView("calendar")}>
                    <CalendarMonth />
                </IconButton>
            </Grid>
            <Grid>
                <IconButton onClick={() => setView("list")}>
                    <FormatListBulleted />
                </IconButton>
            </Grid>
        </Grid>
    );
};
