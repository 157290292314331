import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { renderFieldView } from "../../components/fieldsViews/renderFieldView";
import { Group } from "../../utils/formatGroups";
import { GroupsOptions } from "../../types/groups.types";
import { getFieldExtraOption } from "../../utils/getFieldExtraOption";
import { hideFields } from "../../utils/hideFields";

type SessionDetailGroupProps = {
    group: Group;
    item: any;
    groupOptions?: GroupsOptions;
};

export const SessionDetailGroup = ({ group, item, groupOptions }: SessionDetailGroupProps) => {
    const fields = hideFields(["is_all_day"], group.fields);

    return (
        <TableContainer>
            <Table>
                <TableBody>
                    {fields.map((field) => {
                        const extraOptions = groupOptions?.fieldsWithExtraOptions;
                        const fieldExtraOptions = extraOptions ? getFieldExtraOption(extraOptions, field) : null;

                        return (
                            <TableRow key={field.field}>
                                <TableCell sx={{ maxWidth: 400, border: "0px", padding: 1 }} align="left">
                                    <Typography sx={{ fontWeight: "bold", color: "primary.dark" }} variant="subtitle2">
                                        {field.headerName}
                                    </Typography>
                                    {renderFieldView(field, item, "group", fieldExtraOptions)}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
