
import { StaffWorkLogs } from "./StaffWorkLogs";
import { StaffWorkLogsList } from "./StaffWorkLogsList";

export const staffRoutes = [
    {
        path: "/staff-work-logs",
        element: <StaffWorkLogsList />,
    },
    {
        path: "/staff-work-logs/:id",
        element: <StaffWorkLogs />,
    },
];
