import { Record } from "../../components/record/Record";
import { tabs } from "./scheduleTabs";
import { EntityForm } from "../../components/entityForm/EntityForm";
import { Groups } from "../../components/groups/Groups";
import { DetailTabs } from "../../components/detailTabs/DetailTabs";
import { schedulesOptions } from "./schedulesOptions";
import { SchedulesCalendar } from "./SchedulesCalendar";
import { SchedulesAddMultiple } from "./SchedulesAddMultiple";
import { ScheduleDetailHeader } from "./ScheduleDetailHeader";
import { timeOffOptions } from "./timeOffOptions";
export const fieldsQuery = "*.*,technician.*.*, services.*.*";

export const schedulesRoutes = [
    {
        path: "/schedules",
        element: <SchedulesCalendar />,
    },
    {
        path: "/schedules/:id",
        element: (
            <Record
                entity="schedules"
                fieldsQuery={fieldsQuery}
                type="detail"
                header={<ScheduleDetailHeader />}
                body={<DetailTabs tabs={tabs} />}
            />
        ),
        children: [
            {
                path: "/schedules/:id/detail",
                element: <Groups groupsOptions={schedulesOptions} />,
            },
        ],
    },
    {
        path: "/time_off/:id",
        element: (
            <Record
                entity="time_off"
                fieldsQuery="*.*"
                type="detail"
                header={<ScheduleDetailHeader />}
                body={<DetailTabs tabs={tabs} />}
            />
        ),
        children: [
            {
                path: "/time_off/:id/detail",
                element: <Groups />,
            },
        ],
    },

    {
        path: "/schedules/:id/update",
        element: (
            <Record
                entity="schedules"
                fieldsQuery={fieldsQuery}
                header={<ScheduleDetailHeader />}
                type="update"
                body={<EntityForm groupsOptions={schedulesOptions.groupsOptions} />}
            />
        ),
    },
    {
        path: "/time_off/:id/update",
        element: (
            <Record
                entity="time_off"
                fieldsQuery="*.*"
                type="update"
                header={<ScheduleDetailHeader />}
                body={<EntityForm groupsOptions={timeOffOptions.groupsOptions} />}
            />
        ),
    },
    {
        path: "/schedules/create",
        element: (
            <Record
                entity="schedules"
                fieldsQuery={fieldsQuery}
                type="create"
                body={<EntityForm groupsOptions={schedulesOptions.groupsOptions} />}
            />
        ),
    },
    {
        path: "/time_off/create",
        element: (
            <Record
                entity="time_off"
                fieldsQuery="*.*"
                type="create"
                body={<EntityForm groupsOptions={timeOffOptions.groupsOptions} />}
            />
        ),
    },
    {
        path: "/schedules/add_multiple",
        element: (
            <Record
                entity="schedules"
                fieldsQuery={fieldsQuery}
                type="create"
                header={"no_header"}
                options={schedulesOptions}
                body={<SchedulesAddMultiple />}
            />
        ),
    },
];
